import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState } from "react";
import { HomeContext } from "./context/HomeContext";
import Home from "./page/Home";
import Tour from "./page/Tour";
import Contact from "./page/Contact";
import Music from "./page/Music";
import Videos from "./page/Videos";
import Navbar from "./components/Navbar";
import About from "./page/About";

const AppRouter = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <HomeContext.Provider value={[isMenuOpen, setIsMenuOpen]}>
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tour" element={<Tour />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/music" element={<Music />} />
        <Route path="/videos" element={<Videos />} />
        <Route path="/about" element={<About />} />
      </Routes>
      </Router>
    </HomeContext.Provider>
  );
};

export default AppRouter;
