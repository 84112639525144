import React from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Heading,
  keyframes,
  usePrefersReducedMotion,
} from "@chakra-ui/react";

// Animation de lumière pour l'effet de lueur autour des champs
const glowAnimation = keyframes`
  0% { box-shadow: 0 0 10px rgba(255, 0, 0, 0.2); }
  50% { box-shadow: 0 0 20px rgba(255, 0, 0, 0.5); }
  100% { box-shadow: 0 0 10px rgba(255, 0, 0, 0.2); }
`;

const Contact = () => {
  // Réduire les animations si l'utilisateur préfère un mode sans animation
  const prefersReducedMotion = usePrefersReducedMotion();
  const animation = prefersReducedMotion
    ? undefined
    : `${glowAnimation} 2s ease-in-out infinite`;

  return (
    <Box
      bgGradient="linear(to-r, black, gray.900)" // Fond en dégradé de noir à gris
      color="white"
      minH="100vh"
        display="flex"
      //   alignItems="center"
        justifyContent="center"
      position="relative"
      overflow="hidden"
    >
      {/* Fond en particules (animation) */}
      <Box
        position="absolute"
        top={0}
        left={0}
        width="100vw"
        height="100vh"
        bgGradient="linear(to-b, black, gray.800)"
        zIndex="-1"
        opacity={0.2}
        backgroundImage="url('https://www.transparenttextures.com/patterns/noise-pattern.png')"
        animation="particleMovement 30s infinite linear"
      />

      <Box
        p={8}
        borderRadius="lg"
        boxShadow="lg"
        width="100%"
        maxW="600px"
        transform="scale(1)"
        transition="transform 0.3s ease-out"
        _hover={{
          transform: "scale(1.05)",
        }}
      >
        <Heading
          as="h2"
          size="xl"
          textAlign="center"
          mt={["80px", "80px", "100px", "100px"]}
          mb={6}
          color="red.500"
        >
          CONTACT & BOOKING REQUEST
        </Heading>
        <form>
          <FormControl id="first-name" mb={4} isRequired>
            <FormLabel color="red.500">First Name</FormLabel>
            <Input
              type="text"
              placeholder="Enter your first name"
              bg="black"
              color="white"
              borderColor="red.500"
              _placeholder={{ color: "gray.400" }}
              _hover={{
                boxShadow: "0 0 10px red", // Lueur rouge autour du champ sans démarcation
                borderColor: "transparent", // Supprime la bordure pour éviter la démarcation
              }}
              _focus={{
                boxShadow: "0 0 20px red", // Effet de focus avec lueur autour
                borderColor: "transparent", // Pas de bordure apparente
              }}
            />
          </FormControl>

          <FormControl id="last-name" mb={4} isRequired>
            <FormLabel color="red.500">Last Name</FormLabel>
            <Input
              type="text"
              placeholder="Enter your last name"
              bg="black"
              color="white"
              borderColor="red.500"
              _placeholder={{ color: "gray.400" }}
              _hover={{
                boxShadow: "0 0 10px red", // Lueur rouge autour du champ sans démarcation
                borderColor: "transparent", // Supprime la bordure pour éviter la démarcation
              }}
              _focus={{
                boxShadow: "0 0 20px red", // Effet de focus avec lueur autour
                borderColor: "transparent", // Pas de bordure apparente
              }}
            />
          </FormControl>

          <FormControl id="email" mb={4} isRequired>
            <FormLabel color="red.500">Email</FormLabel>
            <Input
              type="email"
              placeholder="Enter your email"
              bg="black"
              color="white"
              borderColor="red.500"
              _placeholder={{ color: "gray.400" }}
              _hover={{
                boxShadow: "0 0 10px red", // Lueur rouge autour du champ sans démarcation
                borderColor: "transparent", // Supprime la bordure pour éviter la démarcation
              }}
              _focus={{
                boxShadow: "0 0 20px red", // Effet de focus avec lueur autour
                borderColor: "transparent", // Pas de bordure apparente
              }}
            />
          </FormControl>

          <FormControl id="message" mb={6} isRequired>
            <FormLabel color="red.500">Message</FormLabel>
            <Textarea
              placeholder="Enter your message"
              bg="black"
              color="white"
              borderColor="red.500"
              _placeholder={{ color: "gray.400" }}
              _hover={{
                boxShadow: "0 0 10px red", // Lueur rouge autour du champ sans démarcation
                borderColor: "transparent", // Supprime la bordure pour éviter la démarcation
              }}
              _focus={{
                boxShadow: "0 0 20px red", // Effet de focus avec lueur autour
                borderColor: "transparent", // Pas de bordure apparente
              }}
            />
          </FormControl>

          <Flex justify="center">
            <Button
              w={"35%"}
              h={"35px"}
              type="submit"
              colorScheme="red"
              bg="red.500"
              _hover={{
                bg: "red.600",
                boxShadow: "none", // Désactive tout effet de lueur lors du clic
              }}
              size="lg"
              color="black"
            >
              Submit
            </Button>
          </Flex>
        </form>
      </Box>
    </Box>
  );
};

export default Contact;
