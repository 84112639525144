import React, { useRef, useEffect, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Image,
  Link,
  IconButton,
  Progress,
} from "@chakra-ui/react";
import { FaInstagram, FaLink, FaPlay, FaPause } from "react-icons/fa"; // Importer FaPause pour l'icône pause
import { SiSoundcloud, SiTiktok } from "react-icons/si";
import DorfAudio from "../assets/DorfAudio.mp3";
import DorfImage5 from "../assets/Dorf5.png";
import DorfImage4 from "../assets/Dorf4.png";

const Home = () => {
  const audioRef = useRef(null); // Référence pour l'audio
  const [isPlaying, setIsPlaying] = useState(false); // État pour gérer la lecture
  const [currentTime, setCurrentTime] = useState(0); // Temps actuel de lecture
  const [duration, setDuration] = useState(0); // Durée de l'audio

  useEffect(() => {
    const updateCurrentTime = () => {
      if (audioRef.current) {
        setCurrentTime(audioRef.current.currentTime);
      }
    };

    // Mettre à jour le temps de lecture chaque seconde
    const interval = setInterval(updateCurrentTime, 1000);
    return () => clearInterval(interval);
  }, []);

  // Écouter l'événement 'loadedmetadata' pour obtenir la durée de l'audio
  useEffect(() => {
    if (audioRef.current) {
      const handleLoadedMetadata = () => {
        setDuration(audioRef.current.duration);
      };

      // Ajouter l'écouteur pour récupérer la durée une fois que les métadonnées sont chargées
      audioRef.current.addEventListener("loadedmetadata", handleLoadedMetadata);

      return () => {
        // Nettoyage de l'écouteur lorsque le composant est démonté
        if (audioRef.current) {
          audioRef.current.removeEventListener(
            "loadedmetadata",
            handleLoadedMetadata
          );
        }
      };
    }
  }, []);

  // Fonction pour jouer la musique
  const handlePlay = () => {
    if (audioRef.current) {
      audioRef.current.play();
      setIsPlaying(true); // Mettre à jour l'état pour indiquer que la musique est en lecture
    }
  };

  // Fonction pour mettre la musique en pause
  const handlePause = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      setIsPlaying(false); // Mettre à jour l'état pour indiquer que la musique est en pause
    }
  };
  return (
    <Box position="relative">
      <audio ref={audioRef} src={DorfAudio} loop />
      {/* Background Image */}
      <Image
        display={["block", "block", "none", "none"]}
        src={DorfImage5} // Supprimez les crochets, car src ne nécessite pas de tableau
        alt="Dorf Background"
        position="absolute"
        w="100%"
        h="100%"
        objectFit="cover"
        zIndex="-1"
        // Si vous préférez utiliser 'right', vous pouvez faire `right="-20px"`
      />

      <Image
        display={["none", "none", "block", "block"]}
        src={DorfImage4}
        alt="Dorf Background"
        position="absolute"
        w="100%"
        h="100%"
        objectFit="cover"
        zIndex="-1"
      />

      <Box
        position="absolute"
        top="0"
        left="0"
        w="100%"
        h="100%"
        bg="rgba(0,0,0,0.5)"
        zIndex="0"
      />

      {/* Content */}
      <Flex
        direction="column"
        position="relative"
        zIndex="1"
        minH="100vh"
        color="white"
      >
        {/* Liens des réseaux sociaux centrés */}
        <Flex
          direction="column"
          align="center"
          justify="center"
          flex="1"
          mt={["30%", "30%", "10%", "10%"]}
        >
          <Heading as="h2" size="2xl" mb={4}>
            DØRF
          </Heading>
          <Flex mt={4}>
            <Link
              href="https://linktr.ee/raphaeldorf?fbclid=PAAaZKawW5PxTWIGRZXPTs3o8_qqX5AHHkkD-Dy1GoPsxyeizeLF_KIWtPR3M"
              isExternal
              mx={2}
            >
              <IconButton
                aria-label="Linktree"
                icon={<FaLink />} // Icône de lien pour représenter Linktree
                size="lg"
                _hover={{ color: "red.500" }}
              />
            </Link>
            <Link
              href="https://www.instagram.com/raphaeldorf/"
              isExternal
              mx={2}
            >
              <IconButton
                aria-label="Instagram"
                icon={<FaInstagram />}
                size="lg"
                _hover={{ color: "red.500" }}
              />
            </Link>
            <Link href="https://soundcloud.com/raphael-dorf" isExternal mx={2}>
              <IconButton
                aria-label="SoundCloud"
                icon={<SiSoundcloud />}
                size="lg"
                _hover={{ color: "red.500" }}
              />
            </Link>

            <Link
              href="https://www.tiktok.com/@raphaeldorf2?_t=8pZmVXZxKvB&_r=1"
              isExternal
              mx={2}
            >
              <IconButton
                aria-label="TikTok"
                icon={<SiTiktok />}
                size="lg"
                _hover={{ color: "red.500" }}
              />
            </Link>
          </Flex>
        </Flex>
        <Flex justify="center" flexDirection="column" align="center" mb={["15%", '15%' ,'5%', '5%']}>
          <IconButton
            my={['15px', '15px', '25px', '25px']}
            aria-label={isPlaying ? "Pause Music" : "Play Music"} // Change l'aria-label dynamiquement
            icon={isPlaying ? <FaPause /> : <FaPlay />} // Afficher l'icône pause ou play
            boxSize="40px"
            onClick={isPlaying ? handlePause : handlePlay} // Toggle entre play et pause
          />
          <Box width="80%">
            <Progress
              h={"8px"}
              value={duration ? (currentTime / duration) * 100 : 0} // Calculer la progression si la durée est disponible
            />
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Home;
