import React from "react";
import {
  Box,
  Heading,
  VStack,
  Text,
  Button,
  keyframes,
  usePrefersReducedMotion,
} from "@chakra-ui/react";
import { FaDownload } from "react-icons/fa";
import pressKitPDF from "../assets/presskit.pdf"; // Path to your press kit PDF

// Animation de lumière pour l'effet "wow"
const glowAnimation = keyframes`
  0% { box-shadow: 0 0 5px rgba(255, 0, 0, 0.2); }
  50% { box-shadow: 0 0 20px rgba(255, 0, 0, 0.5); }
  100% { box-shadow: 0 0 5px rgba(255, 0, 0, 0.2); }
`;

const About = () => {
  // Vérifier si l'utilisateur préfère réduire les animations
  const prefersReducedMotion = usePrefersReducedMotion();
  const animation = prefersReducedMotion
    ? undefined
    : `${glowAnimation} 2s ease-in-out infinite`;

  return (
    <Box
      bgGradient="linear(to-r, black, gray.900)" // Fond en dégradé de noir à gris
      color="white"
      minH="100vh"
      p={8}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      position="relative"
      overflow="hidden"
    >
      {/* Fond en particules (CSS animé en arrière-plan) */}
      <Box
        position="absolute"
        top={0}

        left={0}
        width="100vw"
        height="100vh"
        bgGradient="linear(to-b, black, gray.800)"
        zIndex="-1"
        opacity={0.2}
        backgroundImage="url('https://www.transparenttextures.com/patterns/noise-pattern.png')"
        animation="particleMovement 30s infinite linear"
      />

      {/* Contenu principal */}
      <Box
        p={8}
        borderRadius="lg"
        boxShadow="lg"
        width="100%"
        mt={["80px", "80px", "100px", "100px"]}
        maxW="800px"
        textAlign="center"
        transform="scale(1)"
        transition="transform 0.3s ease-out"
        _hover={{
          transform: "scale(1.05)",
        }}
        animation={animation}
      >
        <Heading as="h2" size="2xl" mb={6} color="red.500">
          About DØRF
        </Heading>
        <VStack spacing={4}>
          <Text fontSize="lg" color="gray.300">
            DØRF is a dynamic DJ and music producer known for his captivating
            deep house sets, mixing an eclectic blend of beats and rhythms that
            leave audiences spellbound.
          </Text>
          <Text fontSize="lg" color="gray.300">
            From Paris to Tel Aviv, DØRF has headlined in renowned venues,
            spreading good vibes and unique music that resonates with the crowd.
          </Text>

          {/* Button to download Press Kit */}
          <Button
            as="a"
            href={pressKitPDF}
            download="DORF_PressKit.pdf"
            colorScheme="red"
            bg="red.500"
            size="lg"
            leftIcon={<FaDownload />}
            _hover={{ bg: "red.600" }}
          >
            Download Press Kit
          </Button>
        </VStack>
      </Box>
    </Box>
  );
};

export default About;
