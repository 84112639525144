import React from "react";
import {
  Box,
  Heading,
  VStack,
  AspectRatio,
  Text,
  Flex,
} from "@chakra-ui/react";
import yumi from "../assets/yumi.mp3";
import coveryumi from "../assets/coveryumi.png";
import SummerTime from "../assets/SummerTime.mp3";
import summerCover from "../assets/summerCover.png";

const musicTracks = [
  {
    src: yumi,
    title: "YUMI-Take My Breath (DØRF - HARROW EDIT)",
    cover: coveryumi,
  },
  {
    src: SummerTime,
    title: "SUMMER TIME SET (DØRF)",
    cover: summerCover,
  },
];

const Music = () => {
  return (
    <Box
      bgGradient="linear(to-r, black, gray.900)" // Fond en dégradé de noir à gris
      color="white"
      minH="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center" // Centrer verticalement le contenu
    >
      <Heading
        mt={["110px", "110px", "135px", "135px"]}
        as="h1"
        size="2xl"
        mb={8}
        color="red.500"
        textAlign="center"
      >
        Music
      </Heading>

      {/* Conteneur des morceaux */}
      <VStack
        spacing={8}
        display={"flex"}
        flexDirection={"column"}
        align="center"
        justify="center" // Centrer horizontalement et verticalement
        w={["100%", "80%", "50%", "50%"]}
        p={"10px"}
      >
        {musicTracks.map((track, index) => (
          <Box
            key={index}
            w="100%"
            display="flex"
            py={["5px", "5px", "20px", "20px"]}
            flexDirection="column"
            alignItems="center"
          >
            {/* Image de couverture et lecteur audio */}
            <AspectRatio ratio={1 / 1} maxW="500px" mb={4} w="100%">
              <img
                src={track.cover}
                alt={`Cover of ${track.title}`}
                style={{ objectFit: "cover" }}
              />
            </AspectRatio>

            {/* Lecteur audio */}
            <audio controls style={{ width: "100%" }}>
              <source src={track.src} type="audio/mp3" />
              Your browser does not support the audio element.
            </audio>

            {/* Texte du titre centré */}
            <Flex mt={2} justify="center" w="100%" px={2} py={"20px"}>
              <Text
                fontSize="lg"
                fontWeight="bold"
                color="red.300"
                textAlign="center"
              >
                {track.title}
              </Text>
            </Flex>
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default Music;
