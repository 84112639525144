import React, { useState } from "react";
import {
  Box,
  Heading,
  VStack,
  AspectRatio,
  Text,
  Flex,
  IconButton,
} from "@chakra-ui/react";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import dorfvideo1 from "../assets/dorfvideo1.mp4";
import dorfvideo2 from "../assets/dorfvideo2.mp4";
import dorfvideo3 from "../assets/dorfvideo3.mp4";
import dorfvideo5 from "../assets/dorfvideo5.mp4";
import dorfvideo6 from "../assets/dorfvideo6.mp4";
import dorfvideo7 from "../assets/dorfvideo7.mp4";
import dorfvideo8 from "../assets/dorfvideo8.mp4";
import dorfvideo9 from "../assets/dorfvideo9.mp4";
import poster1 from "../assets/poster1.png"; // Images de poster
import poster2 from "../assets/poster2.png";
import poster3 from "../assets/poster3.png";
import poster4 from "../assets/poster4.png";
import poster5 from "../assets/poster5.png";
import poster7 from "../assets/poster7.png";
import poster8 from "../assets/poster8.png";
import poster9 from "../assets/poster9.png";

// Liste des vidéos pour le carousel de Palais de Tokyo
const palaisDeTokyoVideos = [
  { src: dorfvideo3, title: "DØRF at PALAIS DE TOKYO", city: "PARIS", poster: poster1 },
  { src: dorfvideo5, title: "DØRF at PALAIS DE TOKYO", city: "PARIS", poster: poster4 },
  { src: dorfvideo6, title: "DØRF at PALAIS DE TOKYO", city: "PARIS", poster: poster5 },
];

const Videos = () => {
  // État pour l'index de la vidéo actuelle dans le carousel
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  // Fonction pour passer à la vidéo suivante
  const handleNextVideo = () => {
    setCurrentVideoIndex((prevIndex) =>
      prevIndex === palaisDeTokyoVideos.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Fonction pour revenir à la vidéo précédente
  const handlePrevVideo = () => {
    setCurrentVideoIndex((prevIndex) =>
      prevIndex === 0 ? palaisDeTokyoVideos.length - 1 : prevIndex - 1
    );
  };

  return (
    <Box
      bgGradient="linear(to-r, black, gray.900)" // Fond en dégradé de noir à gris
      color="white"
      minH="100vh"
      p={8}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Heading
        mt={["80px", "80px", "100px", "100px"]}
        as="h1"
        size="2xl"
        mb={8}
        color="red.500"
        textAlign="center"
      >
        Videos
      </Heading>

      {/* Conteneur des vidéos */}
      <VStack
        spacing={8}
        display={"flex"}
        flexDirection={"column"}
        align="center"
        maxW="900px"
        width="100%"
      >
        {/* Carousel pour Palais de Tokyo */}
        <Box w="100%" position="relative">
          <AspectRatio ratio={16 / 9}>
            <video
              title={palaisDeTokyoVideos[currentVideoIndex].title}
              src={palaisDeTokyoVideos[currentVideoIndex].src}
              poster={palaisDeTokyoVideos[currentVideoIndex].poster} // Ajout du poster ici
              controls
              allowFullScreen
            />
          </AspectRatio>

          {/* Texte de la vidéo et la ville */}
          <Flex
            mt={2}
            justify="space-between"
            align="center"
            w="100%"
            px={2}
          >
            <Text fontSize="lg" fontWeight="bold" color="red.300">
              {palaisDeTokyoVideos[currentVideoIndex].title}
            </Text>
            <Text fontSize="sm" fontWeight="bold" color="gray.400">
              {palaisDeTokyoVideos[currentVideoIndex].city}
            </Text>
          </Flex>

          {/* Boutons pour naviguer dans le carousel */}
          <IconButton
            icon={<ArrowBackIcon />}
            aria-label="Previous Video"
            position="absolute"
            top="50%"
            left="10px"
            transform="translateY(-50%)"
            onClick={handlePrevVideo}
            colorScheme="red"
            bg="rgba(0, 0, 0, 0.5)"
            _hover={{ bg: "red.500" }}
          />
          <IconButton
            icon={<ArrowForwardIcon />}
            aria-label="Next Video"
            position="absolute"
            top="50%"
            right="10px"
            transform="translateY(-50%)"
            onClick={handleNextVideo}
            colorScheme="red"
            bg="rgba(0, 0, 0, 0.5)"
            _hover={{ bg: "red.500" }}
          />
        </Box>

        {/* Autres vidéos en dehors du carousel */}
        <Box w="100%">
          <AspectRatio ratio={16 / 9}>
            <video
              title="Dorf at Istar"
              src={dorfvideo1}
              poster={poster2} // Ajout du poster ici
              controls
              allowFullScreen
            />
          </AspectRatio>
          <Flex
            mt={2}
            justify="space-between"
            align="center"
            w="100%"
            px={2}
          >
            <Text fontSize="lg" fontWeight="bold" color="red.300">
              DØRF at ISTAR
            </Text>
            <Text fontSize="sm" fontWeight="bold" color="gray.400">
              MADRID
            </Text>
          </Flex>
        </Box>

        <Box w="100%">
          <AspectRatio ratio={16 / 9}>
            <video
              title="Dorf at Deauville"
              src={dorfvideo2}
              poster={poster3} // Ajout du poster ici
              controls
              allowFullScreen
            />
          </AspectRatio>
          <Flex
            mt={2}
            justify="space-between"
            align="center"
            w="100%"
            px={2}
          >
            <Text fontSize="lg" fontWeight="bold" color="red.300">
              DØRF at DEAUVILLE
            </Text>
            <Text fontSize="sm" fontWeight="bold" color="gray.400">
              DEAUVILLE
            </Text>
          </Flex>
        </Box>
        <Box w="100%">
          <AspectRatio ratio={16 / 9}>
            <video
              title="Dorf at Frishman Beach"
              src={dorfvideo7}
              poster={poster7} // Ajout du poster ici
              controls
              allowFullScreen
            />
          </AspectRatio>
          <Flex
            mt={2}
            justify="space-between"
            align="center"
            w="100%"
            px={2}
          >
            <Text fontSize="lg" fontWeight="bold" color="red.300">
              DØRF at FRISHMAN
            </Text>
            <Text fontSize="sm" fontWeight="bold" color="gray.400">
              TEL AVIV
            </Text>
          </Flex>
        </Box>
        <Box w="100%">
          <AspectRatio ratio={16 / 9}>
            <video
              title="Dorf at Secret Garden"
              src={dorfvideo8}
              poster={poster8} // Ajout du poster ici
              controls
              allowFullScreen
            />
          </AspectRatio>
          <Flex
            mt={2}
            justify="space-between"
            align="center"
            w="100%"
            px={2}
          >
            <Text fontSize="lg" fontWeight="bold" color="red.300">
              DØRF at SECRET GARDEN
            </Text>
            <Text fontSize="sm" fontWeight="bold" color="gray.400">
              TEL AVIV
            </Text>
          </Flex>
        </Box>
        <Box w="100%">
          <AspectRatio ratio={16 / 9}>
            <video
              title="Dorf at Bonnie"
              src={dorfvideo9}
              poster={poster9} // Ajout du poster ici
              controls
              allowFullScreen
            />
          </AspectRatio>
          <Flex
            mt={2}
            justify="space-between"
            align="center"
            w="100%"
            px={2}
          >
            <Text fontSize="lg" fontWeight="bold" color="red.300">
              DØRF at BONNIE
            </Text>
            <Text fontSize="sm" fontWeight="bold" color="gray.400">
              PARIS
            </Text>
          </Flex>
        </Box>
      </VStack>
    </Box>
  );
};

export default Videos;
