import React, { useState } from "react";
import {
  Box,
  Flex,
  Link,
  IconButton,
  VStack,
  Heading,
  useDisclosure,
  Collapse,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";

const Navbar = () => {
  const { isOpen, onToggle } = useDisclosure();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    onToggle();
  };

  return (
    <Box position="fixed" top={0} width="100%" zIndex={1000} p={4}>
      {/* Navbar for larger screens */}
      <Flex
        align="center"
        justify="space-between"
        width="100%"
        display={["none", "none", "flex", "flex"]}
      >
        {/* Left side: DØRF logo */}
        <Link
          as={NavLink}
          to="/"
          _hover={{ textDecoration: "none" }}
          color="white"
          fontWeight="bold"
        >
          <Heading
            as="h1"
            fontSize={["3xl", "4xl", "6xl", "6xl"]} // Bigger logo for desktop
            color="white"
            fontFamily="sans-serif"
            // mr={10}
          >
            𝓓Ø𝓡𝓕
          </Heading>
        </Link>

        {/* Right side: Navbar Links */}
        <Flex
          align="center"
          justify="center"
          fontSize="15px"
          fontWeight="bold"
          color="white"
          mr={'14%'}
          gap={10}
          flex={1} // Ensure the links are centered by occupying the remaining space
        >
          <Link
            as={NavLink}
            to="/"
            _hover={{ textDecoration: "none", color: "red.500" }}
            _activeLink={{ color: "red.500" }}
          >
            HOME
          </Link>
          <Link
            as={NavLink}
            to="/music"
            _hover={{ textDecoration: "none", color: "red.500" }}
            _activeLink={{ color: "red.500" }}
          >
            MUSIC
          </Link>
          <Link
            as={NavLink}
            to="/videos"
            _hover={{ textDecoration: "none", color: "red.500" }}
            _activeLink={{ color: "red.500" }}
          >
            VIDEOS
          </Link>
          <Link
            as={NavLink}
            to="/tour"
            _hover={{ textDecoration: "none", color: "red.500" }}
            _activeLink={{ color: "red.500" }}
          >
            TOUR
          </Link>
          <Link
            as={NavLink}
            to="/about"
            _hover={{ textDecoration: "none", color: "red.500" }}
            _activeLink={{ color: "red.500" }}
          >
            ABOUT
          </Link>
          <Link
            as={NavLink}
            to="/contact"
            _hover={{ textDecoration: "none", color: "red.500" }}
            _activeLink={{ color: "red.500" }}
          >
            CONTACT
          </Link>
        </Flex>
      </Flex>

      {/* Hamburger Menu for small screens */}
      <Flex
        align="center"
        justify="space-between"
        display={["flex", "flex", "none", "none"]}
        zIndex={1500}
        position="relative"
      >
        {/* Left side: DØRF logo */}
        <Link
          as={NavLink}
          to="/"
          _hover={{ textDecoration: "none" }}
          color="white"
          fontWeight="bold"
        >
          <Heading as="h1" fontSize="4xl" color="white" fontFamily="sans-serif">
           𝓓Ø𝓡𝓕 
          </Heading>
        </Link>

        <IconButton
          icon={
            isMenuOpen ? (
              <CloseIcon boxSize={"22px"} /> // Larger icon for close button
            ) : (
              <HamburgerIcon boxSize={"27px"} /> // Regular size for hamburger icon
            )
          }
          aria-label="Toggle Navigation"
          onClick={toggleMenu}
          bg="transparent"
          _hover={{ bg: "transparent" }}
          _active={{ bg: "transparent" }}
          color="white"
          zIndex={1501}
          border="none"
          boxShadow="none"
        />
      </Flex>

      {/* Mobile Menu with animation */}
      <Collapse in={isMenuOpen} animateOpacity>
        <VStack
          position="fixed"
          top={0}
          left={0}
          width="100vw"
          height="100vh"
          bg="rgba(0, 0, 0, 0.8)"
          spacing={12}
          justify="center"
          align="center"
          zIndex={1000}
          transition="all 1.2s ease-out"
          transform={isMenuOpen ? "translateY(0)" : "translateY(-100%)"}
          opacity={isMenuOpen ? 1 : 0}
        >
          <Link
            as={NavLink}
            to="/"
            fontSize="3xl"
            fontWeight="bold"
            color="white"
            _hover={{ textDecoration: "none", color: "red.500" }}
            _activeLink={{ color: "red.500" }}
            onClick={toggleMenu}
          >
            HOME
          </Link>
          <Link
            as={NavLink}
            to="/music"
            fontSize="3xl"
            fontWeight="bold"
            color="white"
            _hover={{ textDecoration: "none", color: "red.500" }}
            _activeLink={{ color: "red.500" }}
            onClick={toggleMenu}
          >
            MUSIC
          </Link>
          <Link
            as={NavLink}
            to="/videos"
            fontSize="3xl"
            fontWeight="bold"
            color="white"
            _hover={{ textDecoration: "none", color: "red.500" }}
            _activeLink={{ color: "red.500" }}
            onClick={toggleMenu}
          >
            VIDEOS
          </Link>
          <Link
            as={NavLink}
            to="/tour"
            fontSize="3xl"
            fontWeight="bold"
            color="white"
            _hover={{ textDecoration: "none", color: "red.500" }}
            _activeLink={{ color: "red.500" }}
            onClick={toggleMenu}
          >
            TOUR
          </Link>
          <Link
            as={NavLink}
            to="/about"
            fontSize="3xl"
            fontWeight="bold"
            color="white"
            _hover={{ textDecoration: "none", color: "red.500" }}
            _activeLink={{ color: "red.500" }}
            onClick={toggleMenu}
          >
            ABOUT
          </Link>
          <Link
            as={NavLink}
            to="/contact"
            fontSize="3xl"
            fontWeight="bold"
            color="white"
            _hover={{ textDecoration: "none", color: "red.500" }}
            _activeLink={{ color: "red.500" }}
            onClick={toggleMenu}
          >
            CONTACT
          </Link>
        </VStack>
      </Collapse>
    </Box>
  );
};

export default Navbar;
